var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-provider', _vm._b({
    attrs: {
      "rules": _vm.rules,
      "name": _vm.name
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var errors = _ref.errors,
          valid = _ref.valid,
          invalid = _ref.invalid,
          validated = _ref.validated;
        return [_c('b-form-group', {
          attrs: {
            "description": _vm.description
          }
        }, [_vm._t("label", function () {
          return [_vm.label ? _c('label', {
            class: _vm.labelClasses
          }, [_vm._v(" " + _vm._s(_vm.label) + " ")]) : _vm._e()];
        }), _c('div', {
          class: [{
            'input-group': _vm.hasIcon
          }, {
            focused: _vm.focused
          }, {
            'input-group-alternative': _vm.alternative
          }, {
            'has-label': _vm.label || _vm.$slots.label
          }, _vm.inputGroupClasses]
        }, [_vm.prependIcon || _vm.$slots.prepend ? _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text"
        }, [_vm._t("prepend", function () {
          return [_c('i', {
            class: _vm.prependIcon
          })];
        })], 2)]) : _vm._e(), _vm._t("default", function () {
          return [_c('textarea', _vm._g(_vm._b({
            staticClass: "form-control",
            class: [{
              'is-valid': valid && validated && _vm.successMessage
            }, {
              'is-invalid': invalid && validated
            }, _vm.inputClasses],
            attrs: {
              "rows": _vm.rows,
              "type": _vm.type,
              "valid": valid,
              "required": _vm.required
            },
            domProps: {
              "value": _vm.value
            }
          }, 'textarea', _vm.$attrs, false), _vm.listeners))];
        }, null, _vm.slotData), _vm.appendIcon || _vm.$slots.append ? _c('div', {
          staticClass: "input-group-append"
        }, [_c('span', {
          staticClass: "input-group-text"
        }, [_vm._t("append", function () {
          return [_c('i', {
            class: _vm.appendIcon
          })];
        })], 2)]) : _vm._e(), _vm._t("infoBlock")], 2), _vm._t("success", function () {
          return [valid && validated && _vm.successMessage ? _c('div', {
            staticClass: "valid-feedback"
          }, [_vm._v(" " + _vm._s(_vm.successMessage) + " ")]) : _vm._e()];
        }), _vm._t("error", function () {
          return [errors[0] ? _c('div', {
            staticClass: "invalid-feedback",
            staticStyle: {
              "display": "block"
            }
          }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()];
        })], 2)];
      }
    }], null, true)
  }, 'validation-provider', _vm.$attrs, false));

}
var staticRenderFns = []

export { render, staticRenderFns }