var render = function render(){
  var _vm$provider, _vm$provider2, _vm$provider3, _vm$provider4, _vm$provider5;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', {
    attrs: {
      "align-v": "center",
      "align-h": "center"
    }
  }, [_c('b-col', [_c('b-card', {
    staticClass: "card-profile",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-img', {
    staticClass: "merchant-cover",
    attrs: {
      "src": (_vm$provider = _vm.provider) === null || _vm$provider === void 0 ? void 0 : _vm$provider.cover_image_url_hd
    }
  }), _c('b-row', {
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', [_c('b-card-body', [_c('b-row', {
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', [_c('span', {
    staticClass: "h6 surtitle text-light"
  }, [_vm._v("Merchant")]), _c('h1', {}, [_vm._v(" " + _vm._s((_vm$provider2 = _vm.provider) === null || _vm$provider2 === void 0 ? void 0 : _vm$provider2.card_name) + " ")]), _c('div', _vm._l((_vm$provider3 = _vm.provider) === null || _vm$provider3 === void 0 ? void 0 : _vm$provider3.categories, function (category) {
    return _c('b-badge', {
      key: category.id,
      staticClass: "mr-1",
      attrs: {
        "size": "lg",
        "variant": "primary"
      }
    }, [_vm._v(" " + _vm._s(category.name) + " ")]);
  }), 1)])], 1), !_vm.locked ? _c('b-row', {
    staticClass: "mt-5"
  }, [_c('b-col', {
    attrs: {
      "md": "10"
    }
  }, [_c('b-form-group', {
    staticClass: "form-control-label",
    attrs: {
      "label": "Card Value",
      "label-cols-md": "4",
      "horizontal": ""
    }
  }, [_c('b-form-select', {
    attrs: {
      "rules": "required",
      "name": "Card Value",
      "options": _vm.shopCardsOptions
    },
    on: {
      "change": _vm.select
    },
    model: {
      value: _vm.selectedCard,
      callback: function ($$v) {
        _vm.selectedCard = $$v;
      },
      expression: "selectedCard"
    }
  })], 1), _c('b-form-group', {
    staticClass: "form-control-label mb-0",
    attrs: {
      "label": "Quantity",
      "label-cols-md": "4",
      "horizontal": ""
    }
  }, [_c('base-input', {
    attrs: {
      "type": "number",
      "name": "Quantity",
      "rules": "required|max:500",
      "required": ""
    },
    on: {
      "change": _vm.updateQuantity
    },
    model: {
      value: _vm.quantity,
      callback: function ($$v) {
        _vm.quantity = $$v;
      },
      expression: "quantity"
    }
  })], 1), _c('hr', {
    staticClass: "my-3"
  }), _c('b-form-group', {
    staticClass: "form-control-label",
    attrs: {
      "label": "Total",
      "label-cols-md": "4",
      "horizontal": ""
    }
  }, [_c('base-input', {
    staticClass: "border-0",
    attrs: {
      "plaintext": "",
      "type": "text",
      "name": "Total",
      "value": `$${parseInt(_vm.price.replace('$', '')) * _vm.quantity}`
    }
  })], 1)], 1)], 1) : _vm._e(), _c('b-row', {
    staticClass: "mt-5"
  }, [_c('b-col', [_c('span', {
    staticClass: "h6 surtitle text-light"
  }, [_vm._v(" Merchant Description ")]), _c('div', {
    staticClass: "h5 font-weight-300",
    domProps: {
      "innerHTML": _vm._s((_vm$provider4 = _vm.provider) === null || _vm$provider4 === void 0 ? void 0 : _vm$provider4.long_description)
    }
  })])], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', [_c('span', {
    staticClass: "h6 surtitle text-light"
  }, [_vm._v("Legal")]), _c('div', {
    staticClass: "h5 font-weight-300",
    domProps: {
      "innerHTML": _vm._s((_vm$provider5 = _vm.provider) === null || _vm$provider5 === void 0 ? void 0 : _vm$provider5.legal_disclaimer_html)
    }
  })])], 1)], 1)], 1), _c('b-col', [_c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('h1', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.quantity && !_vm.locked,
      expression: "quantity && !locked"
    }],
    staticClass: "display-3"
  }, [_vm._v(" " + _vm._s(_vm.quantity) + "x ")])]), _vm._l(3, function (n) {
    var _vm$provider6, _vm$provider7;
    return [_c('div', {
      key: n,
      class: `rotate-${n}`
    }, [_c('GiftCard', {
      key: n,
      attrs: {
        "logoUrl": (_vm$provider6 = _vm.provider) === null || _vm$provider6 === void 0 ? void 0 : _vm$provider6.icon_url,
        "companyName": (_vm$provider7 = _vm.provider) === null || _vm$provider7 === void 0 ? void 0 : _vm$provider7.card_name,
        "value": _vm.price
      }
    })], 1)];
  })], 2)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }