var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    class: `item-card ${_vm.selectedClass}`,
    attrs: {
      "title": _vm.title,
      "img-src": _vm.imgSrc,
      "border-variant": "success",
      "img-alt": "Image",
      "img-top": "",
      "tag": "article"
    },
    on: {
      "click": _vm.onSelect
    }
  }, [_c('b-card-text', [_vm._v(" " + _vm._s(_vm.description) + " ")]), _c('b-card-text', [_c('small', {
    staticClass: "d-flex text-muted"
  }, [_vm._v(" " + _vm._s(_vm.example) + " ")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }