var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('b-row', {
          attrs: {
            "align-v": "center"
          }
        }, [_c('b-col', [_c('h3', {
          staticClass: "mb-0"
        }, [_vm._v("Companies")])]), _c('b-col', {
          staticClass: "d-flex justify-content-end"
        }, [!_vm.user.companyId ? _c('router-link', {
          staticClass: "btn btn-sm btn-primary",
          attrs: {
            "to": '/portal/companies/new'
          }
        }, [_vm._v(" Create New ")]) : _vm._e()], 1)], 1), _c('b-row', {
          staticClass: "mt-2"
        }, [_c('b-col', {
          attrs: {
            "sm": "4"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "searchCompaniesInput",
            "type": "search",
            "placeholder": "Search by company name..",
            "debounce": "500"
          },
          model: {
            value: _vm.searchTerms,
            callback: function ($$v) {
              _vm.searchTerms = $$v;
            },
            expression: "searchTerms"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  }, [_c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "table-responsive align-items-center table-flush",
    attrs: {
      "data": _vm.companies,
      "header-row-class-name": "thead-light"
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "Name",
      "sortable": "",
      "prop": "name",
      "min-width": "250px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var row = _ref.row;
        return [_c('b-media', {
          staticClass: "align-items-center",
          attrs: {
            "no-body": ""
          }
        }, [_c('b-img', {
          staticClass: "avatar mr-3",
          attrs: {
            "src": row.logoUrl
          }
        }), _c('b-media-body', [_c('span', {
          staticClass: "font-weight-600 name mb-0 text-sm"
        }, [_vm._v(" " + _vm._s(row.name) + " ")])])], 1)];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Website",
      "min-width": "175px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var row = _ref2.row;
        return [_c('span', [_vm._v(" " + _vm._s(row.url) + " ")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "actions",
      "min-width": "175px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        var row = _ref3.row;
        return [_c('el-dropdown', {
          staticClass: "dropdown",
          attrs: {
            "trigger": "click"
          },
          on: {
            "command": _vm.handleCommand
          }
        }, [_c('b-button', {
          staticClass: "btn btn-sm btn-link my-2"
        }, [_c('b-icon', {
          staticClass: "text-muted",
          attrs: {
            "icon": "three-dots-vertical",
            "font-scale": "1.5"
          }
        })], 1), _c('el-dropdown-menu', {
          staticClass: "dropdown-menu show",
          attrs: {
            "slot": "dropdown"
          },
          slot: "dropdown"
        }, [!_vm.authUserHasCompany && !_vm.authUserIsAdmin ? _c('el-dropdown-item', {
          attrs: {
            "command": {
              action: 'request-membership',
              campaign: row
            }
          }
        }, [_c('b-button', {
          staticClass: "btn btn-link px-0",
          attrs: {
            "variant": "link"
          }
        }, [_vm._v(" Request to Join ")])], 1) : _vm._e(), _vm.authUserIsAdmin ? _c('el-dropdown-item', {
          attrs: {
            "command": {
              action: 'select',
              campaign: row
            }
          }
        }, [_c('b-button', {
          staticClass: "btn btn-link px-0",
          attrs: {
            "variant": "link"
          }
        }, [_vm._v(" Select Company ")])], 1) : _vm._e()], 1)], 1)];
      }
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }