var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    attrs: {
      "show": _vm.show
    },
    on: {
      "close": _vm.close,
      "hide": _vm.close,
      "mousedown": function ($event) {
        if ($event.target !== $event.currentTarget) return null;
        return _vm.close.apply(null, arguments);
      }
    }
  }, [_c('h6', {
    staticClass: "modal-title",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_vm._v(_vm._s(_vm.title))]), _c('div', {
    staticClass: "py-3 text-center"
  }, [_c('b-icon', {
    attrs: {
      "icon": _vm.icon,
      "font-scale": "3",
      "variant": _vm.iconVariant
    }
  }), _c('h3', {
    staticClass: "mt-4"
  }, [_vm._v(" " + _vm._s(_vm.headerText) + " ")]), _c('p', [_vm._v(_vm._s(_vm.bodyText))])], 1), _c('template', {
    slot: "footer"
  }, [_c('base-button', {
    attrs: {
      "v-if": _vm.cancelText,
      "type": "link"
    },
    on: {
      "click": _vm.emitCancelAction
    }
  }, [_vm._v(" " + _vm._s(_vm.cancelText) + " ")]), _c('base-button', {
    staticClass: "ml-auto",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.emitConfirmAction
    }
  }, [_vm._v(" " + _vm._s(_vm.confirmText) + " ")])], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }