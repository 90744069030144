var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', {
    attrs: {
      "no-body": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('b-row', {
          attrs: {
            "align-v": "center"
          }
        }, [_c('b-col', [_c('h3', {
          staticClass: "mb-0"
        }, [_vm._v("User Feedback")])])], 1)];
      },
      proxy: true
    }])
  }, [_c('el-table', {
    ref: "feedbackTable",
    staticClass: "table-responsive align-items-center table-flush",
    attrs: {
      "data": _vm.feedback,
      "header-row-class-name": "thead-light"
    }
  }, [_c('el-table-column', {
    attrs: {
      "sortable": "",
      "label": "Date",
      "prop": "created",
      "min-width": "175px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var row = _ref.row;
        return [_c('span', {
          staticClass: "status ml-2 font-weight-500"
        }, [_vm._v(_vm._s(new Date(row.createdInMs).toLocaleString()))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "sortable": "",
      "label": "Type",
      "prop": "type",
      "min-width": "175px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var row = _ref2.row;
        return [_c('span', {
          staticClass: "status ml-2 font-weight-500"
        }, [_vm._v(_vm._s(row.type))])];
      }
    }])
  }), _c('el-table-column', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": "Message",
      "prop": "message",
      "min-width": "235px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        var row = _ref3.row;
        return [_c('span', {
          staticClass: "status ml-2 font-weight-500"
        }, [_vm._v(_vm._s(row.message))])];
      }
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }