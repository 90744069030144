var render = function render(){
  var _vm$targetOffer, _vm$targetOffer2, _vm$targetOffer3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    staticClass: "d-flex flex-row"
  }, [_vm.subType ? _c('div', {
    staticClass: "d-flex flex-column align-items-center mr-3"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "badge": "",
      "badge-top": "",
      "badge-variant": "success",
      "badge-offset": "-10px",
      "size": "6rem",
      "src": "https://via.placeholder.com/300x200?Text=Placeholder"
    },
    scopedSlots: _vm._u([{
      key: "badge",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "check2"
          }
        })];
      },
      proxy: true
    }], null, false, 3814175994)
  }), _c('span', {
    staticClass: "lead text-muted",
    attrs: {
      "square": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.utils.splitUnderscore((_vm$targetOffer = _vm.targetOffer) === null || _vm$targetOffer === void 0 ? void 0 : _vm$targetOffer.sub_type)) + " ")])], 1) : _vm._e(), (_vm$targetOffer2 = _vm.targetOffer) !== null && _vm$targetOffer2 !== void 0 && _vm$targetOffer2.inventory_sub_type ? _c('div', {
    staticClass: "d-flex flex-column align-items-center"
  }, [_c('b-avatar', {
    attrs: {
      "rounded": "",
      "badge": "",
      "badge-top": "",
      "badge-variant": "success",
      "badge-offset": "-10px",
      "size": "6rem",
      "src": "https://via.placeholder.com/300x200?Text=Placeholder"
    },
    scopedSlots: _vm._u([{
      key: "badge",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "check2"
          }
        })];
      },
      proxy: true
    }], null, false, 3814175994)
  }), _c('span', {
    staticClass: "lead text-muted",
    attrs: {
      "square": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.utils.splitUnderscore((_vm$targetOffer3 = _vm.targetOffer) === null || _vm$targetOffer3 === void 0 ? void 0 : _vm$targetOffer3.inventory_sub_type)) + " ")])], 1) : _vm._e()])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }