var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bg-image"
  }, [_c('CustomHeader', {
    staticClass: "position-static"
  }), _c('div', {
    staticClass: "min-vh-50 my-5"
  }, [_c('b-container', [_c('b-row', {
    staticClass: "h-100 my-5",
    attrs: {
      "align-h": "center",
      "align-v": "center"
    }
  }, [_c('b-col', {
    staticClass: "justify-content-center d-none d-md-flex my-5"
  }, [_c('div', {
    staticClass: "content-image"
  }, [_c('img', {
    staticClass: "img-fluid content-image",
    attrs: {
      "src": require("@/assets/img/screenshot-1.png")
    }
  })])]), _c('b-col', {
    staticClass: "d-flex justify-content-start"
  }, [_c('b-card', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }]
  }, [_c('b-card-title', {
    staticClass: "display-3"
  }, [_vm._v(" Create an account ")]), _c('b-card-text', [_vm._v(" Create a GiftDrop®️ Merchant Account and distribute your gift cards to GiftDrop users. ")]), _c('ValidationObserver', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var handleSubmit = _ref.handleSubmit,
          invalid = _ref.invalid;
        return [_c('form', {
          on: {
            "submit": function ($event) {
              $event.preventDefault();
              return handleSubmit(_vm.createAccount);
            }
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "8"
          }
        }, [_c('base-input', {
          attrs: {
            "type": "text",
            "label": "First Name",
            "name": "First Name",
            "rules": "required|max:30"
          },
          model: {
            value: _vm.first,
            callback: function ($$v) {
              _vm.first = $$v;
            },
            expression: "first"
          }
        }), _c('base-input', {
          attrs: {
            "type": "text",
            "label": "Last Name",
            "name": "Last Name",
            "rules": "required|max:30"
          },
          model: {
            value: _vm.last,
            callback: function ($$v) {
              _vm.last = $$v;
            },
            expression: "last"
          }
        }), _c('base-input', {
          attrs: {
            "type": "email",
            "label": "Email",
            "name": "Email",
            "rules": "required|email"
          },
          model: {
            value: _vm.email,
            callback: function ($$v) {
              _vm.email = $$v;
            },
            expression: "email"
          }
        }), _c('base-input', {
          attrs: {
            "type": "password",
            "label": "Password",
            "name": "password",
            "rules": "required"
          },
          model: {
            value: _vm.password,
            callback: function ($$v) {
              _vm.password = $$v;
            },
            expression: "password"
          }
        }), _c('base-input', {
          attrs: {
            "type": "text",
            "label": "Company Website",
            "name": "Company Website",
            "rules": "required|url"
          },
          model: {
            value: _vm.website,
            callback: function ($$v) {
              _vm.website = $$v;
            },
            expression: "website"
          }
        }), _c('base-input', {
          attrs: {
            "type": "text",
            "label": "Phone",
            "name": "Phone",
            "prependIcon": "telephone-plus",
            "rules": "required|length:10"
          },
          model: {
            value: _vm.phone,
            callback: function ($$v) {
              _vm.phone = $$v;
            },
            expression: "phone"
          }
        }), _c('base-button', {
          attrs: {
            "type": "primary",
            "disabled": invalid,
            "native-type": "submit"
          }
        }, [_vm._v(" Create Account ")])], 1)], 1), _c('b-alert', {
          staticClass: "mt-3",
          attrs: {
            "show": _vm.error,
            "variant": "secondary"
          },
          on: {
            "update:show": function ($event) {
              _vm.error = $event;
            }
          }
        }, [_c('h2', {}, [_vm._v(_vm._s(_vm.title))]), _c('p', [_vm._v(_vm._s(_vm.message))])]), _c('b-alert', {
          staticClass: "my-5",
          attrs: {
            "dismissable": "",
            "show": _vm.success,
            "variant": "success"
          },
          on: {
            "update:show": function ($event) {
              _vm.success = $event;
            }
          }
        }, [_c('h2', {
          staticClass: "text-white"
        }, [_vm._v(_vm._s(_vm.title))]), _c('p', [_vm._v(_vm._s(_vm.message))])])], 1)];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1), _c('ContentFooter')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }