var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', {
    attrs: {
      "no-body": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('b-row', {
          attrs: {
            "align-v": "center"
          }
        }, [_c('b-col', [_vm.companyId ? _c('h6', {
          staticClass: "surtitle mb-0"
        }, [_vm._v("Company")]) : _vm._e(), _c('h3', {
          staticClass: "mb-0"
        }, [_vm._v("Campaigns")])]), _vm.hasActions ? _c('b-col', {
          staticClass: "d-flex justify-content-end"
        }, [_c('b-button', {
          staticClass: "btn btn-link",
          attrs: {
            "size": "sm",
            "variant": "link"
          },
          on: {
            "click": _vm.showArchivedAction
          }
        }, [_vm._v(" " + _vm._s(_vm.showArchived ? 'Hide Archived' : 'Show Archived') + " ")]), _c('router-link', {
          staticClass: "btn btn-sm btn-primary",
          attrs: {
            "to": {
              name: 'Create Campaign'
            }
          }
        }, [_vm._v(" Create New ")])], 1) : _vm._e()], 1), _c('b-row', {
          staticClass: "mt-2"
        }, [_c('b-col', {
          attrs: {
            "sm": "4"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "search",
            "placeholder": "Search by campaign or offer name...",
            "debounce": "500"
          },
          model: {
            value: _vm.searchTerms,
            callback: function ($$v) {
              _vm.searchTerms = $$v;
            },
            expression: "searchTerms"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  }, [_c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "table-responsive align-items-center table-flush",
    attrs: {
      "data": _vm.resultingCampaigns,
      "header-row-class-name": "thead-light"
    }
  }, [_c('el-table-column', {
    attrs: {
      "type": "expand",
      "min-width": "50px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var row = _ref.row;
        return [_c('b-row', [_c('b-col', [_c('span', {
          staticClass: "d-block"
        }, [_vm._v(" Created: " + _vm._s(_vm.methodDate(row.createdInMs)) + " ")]), _c('span', {
          staticClass: "d-block"
        }, [_vm._v(" Offer Name: " + _vm._s(row.offerName) + " ")]), _c('span', {
          staticClass: "d-block"
        }, [_vm._v(" Redeemed: " + _vm._s(row.numRedeemed) + " ")]), _c('span', {
          staticClass: "d-block"
        }, [_vm._v(" Remaining: " + _vm._s(row.numRemaining) + " ")]), _c('b-button', {
          staticClass: "btn btn-link px-0",
          attrs: {
            "size": "sm",
            "variant": "link"
          },
          on: {
            "click": function ($event) {
              return _vm.routeToCampaignDetails(row);
            }
          }
        }, [_vm._v(" Details ")])], 1)], 1)];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Name",
      "min-width": "150px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var row = _ref2.row;
        return [_c('h3', [_vm._v(_vm._s(row.name))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Offer",
      "sortable": "",
      "min-width": "250px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        var _row$offer, _row$offer2, _row$offer2$metadata, _row$offer3, _row$offer3$metadata, _row$offer4, _row$offer4$metadata, _row$offer4$metadata$;
        var row = _ref3.row;
        return [_c('b-row', {
          attrs: {
            "no-gutters": ""
          }
        }, [_c('b-col', {
          attrs: {
            "md": "auto"
          }
        }, [_c('b-avatar', {
          attrs: {
            "src": row === null || row === void 0 ? void 0 : (_row$offer = row.offer) === null || _row$offer === void 0 ? void 0 : _row$offer.hero_url,
            "size": "8rem",
            "rounded": ""
          }
        })], 1), _c('b-col', {
          staticClass: "ml-2 d-flex flex-column justify-content-between"
        }, [_c('div', {
          staticClass: "h4"
        }, [_vm._v(" " + _vm._s(row === null || row === void 0 ? void 0 : (_row$offer2 = row.offer) === null || _row$offer2 === void 0 ? void 0 : (_row$offer2$metadata = _row$offer2.metadata) === null || _row$offer2$metadata === void 0 ? void 0 : _row$offer2$metadata.title) + " ")]), _c('div', {
          staticClass: "small text-muted"
        }, [_vm._v(" " + _vm._s(_vm.utils.truncate(row === null || row === void 0 ? void 0 : (_row$offer3 = row.offer) === null || _row$offer3 === void 0 ? void 0 : (_row$offer3$metadata = _row$offer3.metadata) === null || _row$offer3$metadata === void 0 ? void 0 : _row$offer3$metadata.description, 80)) + " ")]), _c('b-avatar-group', {
          attrs: {
            "size": "2.5rem"
          }
        }, _vm._l(row === null || row === void 0 ? void 0 : (_row$offer4 = row.offer) === null || _row$offer4 === void 0 ? void 0 : (_row$offer4$metadata = _row$offer4.metadata) === null || _row$offer4$metadata === void 0 ? void 0 : (_row$offer4$metadata$ = _row$offer4$metadata.media) === null || _row$offer4$metadata$ === void 0 ? void 0 : _row$offer4$metadata$.imageUrls, function (imageUrl) {
          return _c('b-avatar', {
            key: imageUrl,
            attrs: {
              "src": imageUrl
            }
          });
        }), 1)], 1)], 1)];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Type"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        var _row$offer5, _row$offer6;
        var row = _ref4.row;
        return [_c('b-row', [_c('b-col', {
          staticClass: "d-flex flex-column align-items-center justify-content-around"
        }, [_c('b-badge', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v(" " + _vm._s(_vm.utils.splitUnderscore(row === null || row === void 0 ? void 0 : (_row$offer5 = row.offer) === null || _row$offer5 === void 0 ? void 0 : _row$offer5.sub_type)) + " ")]), _c('div', {
          staticClass: "mt-1"
        }, [_c('b-icon', {
          attrs: {
            "variant": "light",
            "font-size": "2.5rem",
            "icon": _vm.utils.bootstrapIconOfferType(row === null || row === void 0 ? void 0 : (_row$offer6 = row.offer) === null || _row$offer6 === void 0 ? void 0 : _row$offer6.sub_type)
          }
        })], 1)], 1)], 1)];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Reward",
      "min-width": "100px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref5) {
        var _row$offer7, _row$offer8;
        var row = _ref5.row;
        return [_c('b-row', [_c('b-col', {
          staticClass: "d-flex flex-column align-items-center justify-content-around"
        }, [_c('b-badge', {
          attrs: {
            "variant": "secondary"
          }
        }, [_vm._v(" " + _vm._s(_vm.utils.splitUnderscore(row === null || row === void 0 ? void 0 : (_row$offer7 = row.offer) === null || _row$offer7 === void 0 ? void 0 : _row$offer7.inventory_sub_type)) + " ")]), _c('div', {
          staticClass: "mt-1"
        }, [_c('b-icon', {
          attrs: {
            "variant": "light",
            "font-size": "2.5rem",
            "icon": _vm.utils.bootstrapIconOfferinventorySubType(row === null || row === void 0 ? void 0 : (_row$offer8 = row.offer) === null || _row$offer8 === void 0 ? void 0 : _row$offer8.inventory_sub_type)
          }
        })], 1)], 1)], 1)];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Status"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref6) {
        var row = _ref6.row;
        return [_c('b-icon', {
          attrs: {
            "icon": "circle-fill",
            "variant": _vm.computedStatus(row).statusType
          }
        }), _c('span', {
          staticClass: "status ml-2 font-weight-500"
        }, [_vm._v(_vm._s(_vm.computedStatus(row).status))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Consumed",
      "prop": "computedRemainingProgress",
      "min-width": "110px",
      "sortable": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref7) {
        var row = _ref7.row;
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('span', {
          staticClass: "completion mr-2"
        }, [_vm._v(_vm._s(_vm.methodRemainingProgress(row)) + "%")]), _c('div', [_c('base-progress', {
          attrs: {
            "type": "success",
            "value": _vm.methodRemainingProgress(row)
          }
        })], 1)])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Created"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref8) {
        var row = _ref8.row;
        return [_c('small', [_vm._v(_vm._s(_vm.moment(row.created).fromNow()))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "actions",
      "min-width": "175px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref9) {
        var row = _ref9.row;
        return [_c('el-dropdown', {
          staticClass: "dropdown",
          attrs: {
            "trigger": "click"
          },
          on: {
            "command": _vm.handleCommand
          }
        }, [_c('b-button', {
          staticClass: "btn btn-sm btn-link my-2"
        }, [_c('b-icon', {
          staticClass: "text-muted",
          attrs: {
            "icon": "three-dots-vertical",
            "font-scale": "1.5"
          }
        })], 1), _c('el-dropdown-menu', {
          staticClass: "dropdown-menu show",
          attrs: {
            "slot": "dropdown"
          },
          slot: "dropdown"
        }, [_c('el-dropdown-item', {
          attrs: {
            "command": {
              action: 'pause',
              campaign: row
            }
          }
        }, [_vm._v(" " + _vm._s(row.paused ? 'Unpause' : 'Pause') + " ")]), _c('el-dropdown-item', {
          attrs: {
            "command": {
              action: 'details',
              campaign: row
            }
          }
        }, [_vm._v(" Details ")]), _c('el-dropdown-item', {
          attrs: {
            "command": {
              action: 'archive',
              campaign: row
            }
          }
        }, [_vm._v(" Archive ")]), _c('el-dropdown-item', {
          attrs: {
            "divided": "",
            "command": {
              action: 'details',
              campaign: row
            }
          }
        }, [_c('b-button', {
          staticClass: "btn btn-link px-0",
          attrs: {
            "variant": "link"
          }
        }, [_vm._v(" Details ")])], 1)], 1)], 1)];
      }
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }