var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "id": "gift-card-modal",
      "size": "md",
      "scrollable": "",
      "hide-footer": "",
      "title": `${_vm.editing ? 'Update' : 'Create'} Gift Card`
    },
    on: {
      "hidden": _vm.onClose,
      "show": _vm.modalShown
    }
  }, [!_vm.company ? _c('FindOrCreateCompany') : _c('div', [_vm.targetCard ? _c('ValidationObserver', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var _vm$targetCard$openin, _vm$targetCard$number, _vm$targetCard$number2, _vm$targetCard$barcod;
        var handleSubmit = _ref.handleSubmit,
          invalid = _ref.invalid;
        return [_c('b-row', [_c('b-col', {
          staticClass: "mx-auto"
        }, [_c('GiftCard', {
          attrs: {
            "companyName": _vm.company.name,
            "value": (_vm$targetCard$openin = _vm.targetCard.openingBalance) === null || _vm$targetCard$openin === void 0 ? void 0 : _vm$targetCard$openin.toString(),
            "name": _vm.company.name,
            "logoUrl": _vm.company.logoUrl,
            "number": (_vm$targetCard$number = _vm.targetCard.number) === null || _vm$targetCard$number === void 0 ? void 0 : _vm$targetCard$number.toString()
          }
        })], 1)], 1), _c('form', {
          on: {
            "submit": function ($event) {
              $event.preventDefault();
              return handleSubmit(_vm.handleSave);
            }
          }
        }, [_c('b-row', [_c('b-col', [_c('base-input', {
          attrs: {
            "type": "text",
            "name": "Card Number",
            "rules": "required|max:16",
            "label": "Card Number",
            "prepend-icon": "credit-card2-front",
            "maxLength": 16,
            "value": (_vm$targetCard$number2 = _vm.targetCard.number) === null || _vm$targetCard$number2 === void 0 ? void 0 : _vm$targetCard$number2.toString()
          },
          on: {
            "input": _vm.updateCardNumber
          }
        }), _c('base-input', {
          attrs: {
            "type": "number",
            "name": "Card Value",
            "rules": "required|between:1,500",
            "label": "Card Value",
            "prepend-icon": "cash",
            "value": _vm.targetCard.openingBalance
          },
          on: {
            "input": _vm.updateCardBalance
          }
        })], 1)], 1), _c('hr', {}), _c('h6', {
          staticClass: "heading-small text-muted mb-4"
        }, [_vm._v("Card Settings")]), _c('b-row', [_c('b-col', [_c('b-form-group', {
          staticClass: "form-control-label",
          attrs: {
            "label": "Add a secondary value?",
            "description": "This is usually a code or pin."
          }
        }, [_c('base-switch', {
          attrs: {
            "on-text": "Yes",
            "off-text": "No"
          },
          on: {
            "input": _vm.secondarySwitch
          },
          model: {
            value: _vm.showSecondary,
            callback: function ($$v) {
              _vm.showSecondary = $$v;
            },
            expression: "showSecondary"
          }
        })], 1), _c('transition', {
          attrs: {
            "name": "fade",
            "mode": "out-in"
          }
        }, [_vm.showSecondary ? _c('b-row', {
          staticClass: "my-3"
        }, [_c('b-col', [_c('base-input', {
          attrs: {
            "type": "text",
            "name": "Secondary Display Name",
            "rules": "required",
            "label": "Secondary Number Display Name",
            "prepend-icon": "card-text",
            "value": _vm.targetCard.subNumber.label
          },
          on: {
            "input": _vm.updateCardSubLabel
          }
        }), _c('base-input', {
          attrs: {
            "type": "text",
            "name": "Secondary Number",
            "rules": "required|max:30",
            "label": "Secondary Number",
            "prepend-icon": "hash",
            "value": _vm.targetCard.subNumber.number
          },
          on: {
            "input": _vm.updateCardSubNumber
          }
        })], 1)], 1) : _vm._e()], 1), _c('b-form-group', {
          staticClass: "form-control-label",
          attrs: {
            "label": "Add Barcode?"
          }
        }, [_c('base-switch', {
          attrs: {
            "on-text": "Yes",
            "off-text": "No"
          },
          on: {
            "input": _vm.barcodeSwitch
          },
          model: {
            value: _vm.showBarcode,
            callback: function ($$v) {
              _vm.showBarcode = $$v;
            },
            expression: "showBarcode"
          }
        })], 1), _c('transition', {
          attrs: {
            "name": "fade",
            "mode": "out-in"
          }
        }, [_vm.showBarcode ? _c('b-row', {
          staticClass: "my-3"
        }, [_c('b-col', [_c('base-input', {
          attrs: {
            "type": "text",
            "name": "Barcode Value",
            "rules": "required|max:16",
            "maxLength": 16,
            "prepend-icon": "hash",
            "label": "Barcode Number",
            "value": (_vm$targetCard$barcod = _vm.targetCard.barcode) === null || _vm$targetCard$barcod === void 0 ? void 0 : _vm$targetCard$barcod.number
          },
          on: {
            "input": _vm.updateBarcode
          }
        })], 1)], 1) : _vm._e()], 1)], 1)], 1), _c('b-row', [_c('b-col', {
          staticClass: "d-flex flex-row justify-content-between"
        }, [_c('base-button', {
          staticClass: "ml-auto",
          attrs: {
            "type": "link"
          },
          on: {
            "click": function ($event) {
              return _vm.onClose();
            }
          }
        }, [_vm._v(" Cancel ")]), _c('base-button', {
          attrs: {
            "type": "primary",
            "native-type": "submit",
            "disabled": _vm.loading || invalid
          }
        }, [_vm._v(" " + _vm._s(_vm.editing ? 'Update' : 'Create') + " ")])], 1)], 1)], 1)];
      }
    }], null, false, 278310174)
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }