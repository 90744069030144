var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.src ? _c('div', {
    staticClass: "image-container",
    on: {
      "mouseover": function ($event) {
        _vm.hover = true;
      },
      "mouseleave": function ($event) {
        _vm.hover = false;
      }
    }
  }, [_vm.pdfSource ? _c('pdf', {
    attrs: {
      "src": _vm.pdfSource
    }
  }) : _vm.video ? _c('b-embed', {
    staticStyle: {
      "object-fit": "scale-down"
    },
    attrs: {
      "type": "video",
      "controls": "",
      "src": _vm.src
    }
  }) : _c('img', {
    staticClass: "p-3 media-image",
    attrs: {
      "src": _vm.src
    }
  }), _vm.hover && !_vm.disabled ? _c('div', {
    staticClass: "remove-container p-3 rounded",
    attrs: {
      "click": _vm.onRemove
    }
  }, [_c('b-icon', {
    staticClass: "d-flex",
    attrs: {
      "variant": "light",
      "scale": "2",
      "icon": "trash",
      "aria-hidden": "true"
    },
    on: {
      "click": _vm.onRemove
    }
  })], 1) : _vm._e()], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }