var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('b-row', {
          attrs: {
            "align-v": "center"
          }
        }, [_c('b-col', [_c('h6', {
          staticClass: "surtitle mb-0"
        }, [_vm._v("Company")]), _c('h3', {
          staticClass: "mb-0"
        }, [_vm._v(_vm._s(_vm.isPending ? 'Pending Members' : 'Members'))])])], 1)];
      },
      proxy: true
    }])
  }, [_vm.members && !_vm.loading ? _c('b-row', [_c('Table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    ref: "usersTable",
    staticClass: "table-responsive align-items-center table-flush",
    attrs: {
      "data": _vm.members,
      "header-row-class-name": "thead-light",
      "default-sort": {
        prop: 'created',
        order: 'descending'
      },
      "row-class-name": _vm.tableRowClassName
    }
  }, [_c('TableColumn', {
    attrs: {
      "sortable": "",
      "label": "Name",
      "prop": "last",
      "min-width": "175px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var row = _ref.row;
        return [_c('span', {
          staticClass: "status font-weight-500"
        }, [_vm._v(" " + _vm._s(row.first ? row.first : '' + ' ' + row.last ? row.last : '') + " ")])];
      }
    }], null, false, 3181807316)
  }), _c('TableColumn', {
    attrs: {
      "sortable": "",
      "label": "Email",
      "prop": "email",
      "min-width": "235px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var row = _ref2.row;
        return [_c('span', {
          staticClass: "status ont-weight-500"
        }, [_vm._v(_vm._s(row.email))])];
      }
    }], null, false, 4122509050)
  }), _c('TableColumn', {
    attrs: {
      "label": "Phone",
      "min-width": "175px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        var row = _ref3.row;
        return [_c('span', {
          staticClass: "status font-weight-500"
        }, [_vm._v(_vm._s(row.phone))])];
      }
    }], null, false, 2560014892)
  }), _c('TableColumn', {
    attrs: {
      "sortable": "",
      "label": "Role",
      "prop": "role",
      "min-width": "175px",
      "filters": [{
        text: 'USER',
        value: 'USER'
      }, {
        text: 'MERCHANT',
        value: 'MERCHANT'
      }, {
        text: 'ADMIN',
        value: 'ADMIN'
      }],
      "filter-method": _vm.filterHandler
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        var row = _ref4.row;
        return [_vm.companyOwnerId === row.id ? _c('b-badge', {
          staticClass: "status ml-2 font-weight-500",
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(" OWNER ")]) : row.role === 'MERCHANT' ? _c('b-badge', {
          staticClass: "status ml-2 font-weight-500",
          attrs: {
            "variant": "success"
          }
        }, [_vm._v(" MERCHANT ")]) : _vm._e()];
      }
    }], null, false, 1536379195)
  }), _vm.isOwnerOrAdmin ? _c('TableColumn', {
    attrs: {
      "label": "actions",
      "min-width": "175px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref5) {
        var row = _ref5.row;
        return [_c('Dropdown', {
          staticClass: "dropdown",
          attrs: {
            "trigger": "click"
          },
          on: {
            "command": _vm.handleCommand
          }
        }, [_c('b-button', {
          staticClass: "btn btn-sm btn-link my-2"
        }, [_c('b-icon', {
          staticClass: "text-muted",
          attrs: {
            "icon": "three-dots-vertical",
            "font-scale": "1.5"
          }
        })], 1), _c('DropdownMenu', {
          staticClass: "dropdown-menu show",
          attrs: {
            "slot": "dropdown"
          },
          slot: "dropdown"
        }, [_vm.isPending ? _c('DropdownItem', {
          attrs: {
            "command": {
              action: 'accept',
              userId: row.id
            }
          }
        }, [_c('b-button', {
          staticClass: "btn btn-link px-0",
          attrs: {
            "variant": "link"
          }
        }, [_vm._v(" Accept ")])], 1) : _vm._e(), !_vm.isPending && !(row.id === _vm.currentUser.id) ? _c('DropdownItem', {
          attrs: {
            "command": {
              action: 'make-owner',
              userId: row.id
            }
          }
        }, [_vm._v(" Make Owner ")]) : _vm._e(), !_vm.isPending && row.id === _vm.currentUser.id ? _c('DropdownItem', {
          attrs: {
            "command": {
              action: 'go-to-account',
              userId: row.id
            }
          }
        }, [_vm._v(" Account ")]) : _vm._e(), !_vm.isPending && !(row.id === _vm.currentUser.id) ? _c('DropdownItem', {
          attrs: {
            "command": {
              action: 'remove',
              userId: row.id
            }
          }
        }, [_vm._v(" Remove ")]) : _vm._e()], 1)], 1)];
      }
    }], null, false, 2985329920)
  }) : _vm._e()], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }