var render = function render(){
  var _ref;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav', {
    attrs: {
      "aria-label": "breadcrumb"
    }
  }, [_c('b-breadcrumb', {
    class: [(_ref = {}, _ref[`bg-${_vm.type}`] = _vm.type, _ref), _vm.listClasses]
  }, [_vm._t("default")], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }